<template>
  <div>
    <b-card>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="create()"
          ref="kt_save_changes"
          v-if="!editUser"
        >
          Add New User
        </button>
      </div>
      <div v-if="!editUser">
        <b-table
          striped
          hover
          class='clickabletd'  
          sort-icon-left                  
          :items="currentUsersData.users"
          :fields="userFields"
          :tbody-tr-class="rowClass"
          @row-clicked="rowClickHandler"
        >
          <template #cell(actions)="data">
            <v-icon small v-on:click="deleteRecord(data.item.id,data.item.active)" v-if="!data.item.isPrimary && data.item.active"
              >mdi-delete</v-icon
            >
            <v-icon small v-on:click="deleteRecord(data.item.id,data.item.active)" v-if="!data.item.active"
              >mdi-restart</v-icon
            >
          </template>
          <template #cell(role_id)="data">
              <span @click="rowClickHandler(data.item,0)">{{getUserRole(data.item.role_id)}}</span>
          </template>
          <template #cell(alertchange)="data">
            <b-form-checkbox
                v-model="data.item.alertchange"
                value=1
                unchecked-value=0
                @change="toggleAlert(data.item)"
            ></b-form-checkbox>              
          </template>
          <template #cell(department_id)="data">
              <span @click="rowClickHandler(data.item,0)">{{getUserDepartment(data.item.department_id)}}</span>
          </template>
          <template #cell(organisation_id)="data">
              <span @click="rowClickHandler(data.item,0)">{{getUserOrganisation(data.item.organisation_id)}}</span>
          </template>
          <template #cell(email)="data">

            <table  class="table table-borderless">
                <tr style="background-color:transparent">
                    <td width="30%">
                      <div
                        class="symbol symbol-60 symbol-xxl-35 mr-5 align-self-start align-self-xxl-center"
                      >                      
                        <div
                          @click="rowClickHandler(data.item,0)"
                          class="symbol-label"
                          :style="{
                            backgroundImage: `url(${picture(data.item)})`,
                          }"
                        ></div>        
                      </div>
                    </td>
                    <td  width="70%"  @click="rowClickHandler(data.item,0)">
                        <b>{{data.item.name}}</b><br/>
                        {{data.item.email}}

                    </td>
                </tr>                    
            </table>

          </template>
        </b-table>
      </div>

      <div v-if="editUser">
        <KTUserInformation
          :record="editUserRecord"
          @canceled="editUser = false"
          @finished="finished()"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_USERS,
  DELETE_USER_DETAILS,
  UPDATE_USER_DETAILS
} from "@/core/services/store/users.module";

import KTUserInformation from "@/view/pages/management/users/UserInformation";

export default {
  name: "workplans",
  components: {
    KTUserInformation,
  },
  data() {
    return {
      editUser: false,
      editUserRecord: [],

      userFields: [
        { key: "email", label: "Username / Email", sortable: true },
        { key: "alertchange", label: "Alert" },
        { key: "company_name", sortable: true },
        { key: "job_title", sortable: true },
        { key: "mobile", sortable: true },
        { key: "department_id" },
        { key: "organisation_id" },
        { key: "role_id" },
        { key: "actions", label: "" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Manage Users" }]);
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch(GET_USERS);
    },
    rowClass(item, type) {
      console.log(type,item)
        if (item.active != true) { 
          return 'text-deleted'
        } else {
          return ''
        } 
    },
    finished() {
      this.editUser = false;
      this.editUserRecord = [];
      setTimeout(() => {
        this.getData();
      }, 500);
    },
    rowClickHandler(record, index) {
      console.log(record, index); // This will be the item data for the row
      this.editUser = true;
      this.editUserRecord = record;
    },

    toggleAlert(item){
        var name = item.name;
        var email = item.email;
        var job_title = item.job_title;
        var department_id = item.department_id;
        var role_id = item.role_id;
        var alertchange = item.alertchange;
        var id = item.id;   
        
        this.$store
          .dispatch(UPDATE_USER_DETAILS, {
            id,
            name,
            email,
            job_title,
            department_id,
            role_id,
            alertchange
          })        
    },   

    create() {
      this.editUser = true;
      this.editUserRecord = {
        id: -1,
        name: "",
        email: "",
        company_name: "",
        mobile: "",
        job_title: "",
        department: "",
      };
    },
    deleteRecord(id,active) {
      
      var text = "Do you really want to delete?";
      if (!active ) {text = "Do you really want to restore?"; }
      if (confirm(text)) {
        this.editUser = false;
        this.$store.dispatch(DELETE_USER_DETAILS, {
          id,
        });
        setTimeout(() => {
          this.getData();
        }, 500);
      }
    },
    picture(user) {

        return (
          process.env.VUE_APP_BACKEND_ENDPOINT + user.profile_image
        );

    },    
    getUserRole(role_id) {

      var retVal = 'n/a';
      this.currentUsersData.user_roles.forEach(userRole => {
        if (userRole.id == role_id) { retVal = userRole.name }        
      });

      return retVal;

    },
    getUserDepartment(department_id) {

      var retVal = 'n/a';
      this.currentUsersData.departments.forEach(department => {
        if (department.id == department_id) { retVal = department.name }        
      });

      return retVal;

    },
    getUserOrganisation(organisation_id) {

      var retVal = 'n/a';
      this.currentUsersData.organisations.forEach(organisation => {
        if (organisation.id == organisation_id) { retVal = organisation.name }        
      });

      return retVal;

    }

  },
  computed: {
    ...mapGetters(["currentUsersData","isAuthenticated"]),
    getName() {
      var result = "";
      if (this.currentFramework.framework) {
        result = this.currentFramework.framework.name;
      }
      return result;
    },
    getFrameworkHeaders() {
      var results = [];
      if (this.currentFramework.framework_items) {
        this.currentFramework.framework_items.forEach((framework_item) => {
          var targetListResult = results.filter(function(o) {
            return o.function_code == framework_item.function_code;
          });
          if (targetListResult.length === 0) {
            results.push({
              function_code: framework_item.function_code,
              function: framework_item.function,
            });
          }
        });
      }
      return results;
    },
  },
};
</script>
