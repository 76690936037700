<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id != -1"
        >
          Edit User
        </h3>
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id == -1"
        >
          Create User
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Email Address / Username</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                ref="email"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                v-model.trim="$v.userRecord.email.$model"
              />
            </div>
            <div class="error" v-if="!$v.userRecord.email.required">
              <i>Email is required</i>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.userRecord.name.$model"
            />
            <div class="error" v-if="!$v.userRecord.name.required">
              <i>Name is required</i>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Mobile</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="mobile"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="userRecord.mobile"
            />
          </div>
        </div>
        <!-- <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Company Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="company_name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="userRecord.company_name"
            />
          </div>
        </div> -->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Job title</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="job_title"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="userRecord.job_title"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Department</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
                ref="department_id"
                v-model="userRecord.department_id"
                :options="departmentItems"
            ></b-form-select>
          </div>
          <div class="error" v-if="!$v.userRecord.department_id.required">
              <i>Department is required</i>
          </div>            
        </div>        
<!--        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Organisation</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
                ref="organisation_id"
                v-model="userRecord.organisation_id"
                :options="organisationItems"
            ></b-form-select>
          </div>
          <div class="error" v-if="!$v.userRecord.organisation_id.required">
              <i>Organisation is required</i>
          </div>          

        </div>        -->
        <div class="form-group row" v-if="!userRecord.isPrimary">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >User Role</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
                ref="role_id"
                v-model="userRecord.role_id"
                :options="userroleItems"
            ></b-form-select>
          </div>
          
          <div class="error" v-if="!$v.userRecord.role_id.required">
              <i>Role is required</i>
          </div>          


        </div>       
        <div class="form-group row" v-if="!userRecord.isPrimary">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            ></label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-checkbox
                ref="alertchange"
                v-model="userRecord.alertchange"
                value=1
                unchecked-value=0
            >Alert On Change</b-form-checkbox>
          </div>
          
          <div class="error" v-if="!$v.userRecord.role_id.required">
              <i>Role is required</i>
          </div>          


        </div>          
        <div class="form-group row" v-if="userRecord.isPrimary">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >User Role</label
          >
          <div class="col-lg-9 col-xl-6">
            <b>As you are the registering user you are always set to Administrator</b>
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  UPDATE_USER_DETAILS,
  CREATE_USER_DETAILS,
} from "@/core/services/store/users.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "PersonalInformation",
  props: {
    record: Object,
  },
  data() {
    return {
      userRecord: this.record,

    };
  },
  validations: {
    userRecord: {
      name: {
        required,
      },
      email: {
        required,
      },
      role_id: {
        required,
      },
      /*
      organisation_id: {
        required,
      },
      */
      department_id: {
        required,
      },

    },

  },
  mounted() {
    console.log(this.currentUser);
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        var name = this.$refs.name.value;
        var company_name = '' //this.$refs.company_name.value;
        var email = this.$refs.email.value;
        var mobile = this.$refs.mobile.value;
        var job_title = this.$refs.job_title.value;
        var department_id = this.$refs.department_id.value;
        var organisation_id = '' //this.$refs.organisation_id.value;
        var role_id = this.$refs.role_id.value;
        var alertchange = this.userRecord.alertchange;
        var id = this.userRecord.id;

        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        if (id == -1) {
          // send update request
          this.$store
            .dispatch(CREATE_USER_DETAILS, {
              id,
              name,
              email,
              company_name,
              mobile,
              job_title,
              department_id,
              organisation_id,
              role_id,
              alertchange
            })
            .then(() => {
              this.$emit("finished");
            });
        } else {
          // send update request
          this.$store
            .dispatch(UPDATE_USER_DETAILS, {
              id,
              name,
              email,
              company_name,
              mobile,
              job_title,
              department_id,
              organisation_id,
              role_id,
              alertchange
            })
            .then(() => {
              this.$emit("finished");
            });
        }

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
    cancel() {
      this.$emit("canceled");
    },
  },
  computed: {
    ...mapGetters(["currentUser","currentUsersData"]),
    departmentItems() {
      var results = [];
      results.push({
        value: '',
        text: 'Not Selected',
      });
      if (this.currentUsersData.departments) {      
        this.currentUsersData.departments.forEach((item) => {
          results.push({
            value: item.id,
            text: item.name,
          });
        });
      }
      return results;
    },    
    organisationItems() {
      var results = [];
      results.push({
        value: '',
        text: 'Not Selected',
      });
      if (this.currentUsersData.organisations) {      
        this.currentUsersData.organisations.forEach((item) => {
          results.push({
            value: item.id,
            text: item.name,
          });
        });
      }
      return results;
    },    
    userroleItems() {
      var results = [];
      results.push({
        value: '',
        text: 'Not Selected',
      });
      if (this.currentUsersData.user_roles) {      
        this.currentUsersData.user_roles.forEach((item) => {
          results.push({
            value: item.id,
            text: item.name,
          });
        });
      }
      return results;
    },    
  },
  watch: {
    record: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>
<style>
.error i {
  color: #cc0000;
}
</style>
